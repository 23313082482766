
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Home");
    });
  },
  data: () => ({
    slides: [
        'https://vivo247.com/wp-content/uploads/2020/07/puente-vidrio-china.jpg',
        'https://info7rm.blob.core.windows.net.optimalcdn.com/images/2018/10/20/puente.jpg'
      ]
  }),
});
